import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"



const IndexPage = () => (
  <Layout>
    <Seo title="Online & In-person Speech Therapy for Children & Adults." />
    {/* <Container className="d-lg-flex heroWidth">
        <div lg={6} className="">
          <h1></h1>
          
        </div>
        <div className="">
          <StaticImage
            src="../images/child-white-shirt.jpg"
            loading="eager"
            width={350}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Child in white shirt talking into a microphone"
          />
        </div>
    </Container> */}
<div className="masthead mb-5">
<Container>
    <Row>
          <Col lg={6} className="ps-lg-5 pe-lg-3 pt-0 text-center pb-5 py-lg-5 d-flex align-self-center flex-column">
            <h1>Online & in-person speech therapy for children and adults.</h1>
            {/* <hr /> */}
            <div>
              <Link to="/services/" className="btn btn-outline-light me-3">Our Services</Link>
              <Link to="/contact/" className="btn btn-outline-light">Contact Us</Link>
          </div>          </Col>
          <Col lg={6} className="px-5 px-md-0 text-center">
            <div className="indexBoxShadowPadding">
              <StaticImage
                src="../images/kid-with-blue-headphones-on-laptop.jpg"
                loading="eager"
                quality={95}
                height={342}
                formats={["auto", "webp", "avif"]}
                alt="Young child with headphones on sitting in front of a laptop"
              />            
            </div>
          </Col>
          
        </Row>
    </Container>
</div>
    


    <Container className="singleCol mt-3 mt-lg-4">
      <p className="lead">
        Our online sessions offer a personal connection tailored to your strengths and needs. You will see more progress in less time, with the privacy and flexibility that you value.
      </p>
      <p className="lead">
        We are committed to inclusive practices and value the diverse backgrounds and experiences of our clients.
      </p>
      <p className="lead">
        From speech and language therapy, to the Minecraft gaming club, all of us at Vibe are eager to walk the path of improved communication skills with you and look forward to meeting you!
      </p>
      <hr className="mt-4" />
    </Container>
    <Container className="singleCol py-2 py-lg-4">
      <h2 className="text-center mt-3">Proud Member Of</h2>
      <Row>
        
        <Col lg={4} className="float-img text-center d-flex align-items-center">
          <StaticImage
            src="../images/ASHA_organization_logo.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="ASHA org logo"
            className=""
          />  
        </Col>
        <Col lg={4} className="float-img text-center d-flex align-items-center">
          <StaticImage
            src="../images/FFW-Provider-Logo-color.png"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="FFW Provider Logo"
            className=""
          />  
        </Col>
      </Row>
    </Container>
    
  </Layout>
)

export default IndexPage
